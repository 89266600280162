.app-main-content {
/*    background-color: #000000;
    background-image: linear-gradient(315deg, #2b303b 0%, #2e3440 74%);

 */
    padding: 24px 16px 54px 16px;
    overflow: initial;
    margin-top: 64px;
}

.app-main-container {
    max-width: 896px;
    margin: 0px auto;
}

hr {
    border: none;
    background: #FFF;
    height: 1px;
}

h2 {
    font-size: 1.4rem;
}

.ant-layout-header {
    padding: 0 20px;
    width: 100%;
}

.menuBar {
    padding: 0 0px;
    max-width: 896px;
    margin: 0px auto;
}

.logo {
    width: 150px;
    float: left;
}


.menuCon {
    width: calc(100% - 150px);
    float: left;
    overflow: hidden;
}

.menuCon .ant-menu-item {
    padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
    border-bottom: none;
}


.menuCon .rightMenu {
    /*float: right;*/
}

.menuCon .rightMenu > ul {
    display: block;
    text-align: right;
}

.barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 16px;
    display: none;
    background: none;
}

.barsBtn {
    display: block;
    width: 20px;
    height: 2px;
    background: var(--global-main-color);
    position: relative;
}

.barsBtn:after,
.barsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: var(--global-main-color);
}

.barsBtn:after {
    top: auto;
    bottom: -6px;
}

.ant-drawer-body {
    padding: 0;
}

.barsMenu>span {
    display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
    display: inline-block;
    width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
    border-bottom-color: transparent;
}

.rdp-month, .rdp-table {
    max-width: 100%;
    width: 100%;
}

.rdp-button {
    margin: 0px auto;
}

.rdp-day_outside {
    color: rgba(255,255,255,0.3);
}

.rdp-day_today {
    border: 1px solid gray;
}

@media (max-width: 767px) {
    .barsMenu {
        display: inline-block;
    }

    .rightMenu {
        display: none;
    }

    .logo a {
        margin-left: -20px;
    }

    .menuCon .ant-menu-item,
    .menuCon .ant-menu-submenu-title {
        padding: 1px 20px;
    }

    .logo a {
        padding: 10px 20px;
    }
}
