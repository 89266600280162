@import '../custom-theme.css';
@import "~react-day-picker/dist/style.css";

:root {
  --global-text-color: rgba(255, 255, 255, 0.85);
  --global-main-color: var(--antd-wave-shadow-color);
  --global-second-color: #FFF;
  --global-main-color-darken: #242831;
  --global-calendar-header-colors: #1c212d;
  --global-calendar-border-colors: #7b7b7b;
  --global-red-color: #CF6679;


  --rdp-cell-size: 40px;
  --rdp-accent-color: var(--global-main-color);
  --rdp-background-color: var(--global-main-color);
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: var(--global-main-color);
  --rdp-background-color-dark: var(--global-second-color);
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: rgba(255, 255, 255, 0.85);
  //background-color: #141414;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: var(--global-red-color);
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}


.ql-snow .ql-stroke {
  stroke: rgba(255,255,255,0.85) !important;
}

.ql-snow button:hover .ql-stroke {
  stroke: var(--global-second-color) !important;
}

.ql-snow .ql-picker {
  color: rgba(255,255,255,0.85) !important;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--global-second-color) !important;
}

.ql-snow .ql-picker-options {
  background-color: #1f1f1f !important;
}

.ql-container.ql-snow {
  border: 1px solid var(--global-calendar-border-colors) !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--global-calendar-border-colors) !important;;
}



.action-button-table {
  font-size: 1.2rem;
}

.action-button-table.action-button-selected {
  color: var(--global-main-color);
}

.action-button-table:hover {
  color: var(--global-calendar-border-colors);
}

/*
###### Cookie banner
 */

.react-cookie-law-option-wrapper label{
  color: #000 !important;
}

.react-cookie-law-container a {
  margin-left: 0px !important;
}

.react-cookie-law-header {
  font-weight: bold;
  font-size: 1.3rem;
}

.react-cookie-law-dialog {
  background: rgba(6, 0, 0, 0.5) !important;
  bottom: 0;
  padding: 10px !important;
  position: relative;
}

.react-cookie-law-container {
  position: fixed;
  top: 40%;
  left: 10px;
  right: 10px;
  background: #f6f6f8 !important;
  padding: 25px;
  border-radius: 5px;
}

.react-cookie-law-accept-btn,
.react-cookie-law-manage-btn,
.react-cookie-law-save-btn {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 40px;
  padding: 6px 15px !important;
  font-size: 16px;
  border-radius: 5px;
  outline: 0;
  border: 1px solid;
  box-shadow: none;
}

.react-cookie-law-manage-btn,
.react-cookie-law-save-btn{
  background: none !important;
  color: #000 !important;
  border-color: #000 !important;
}

.react-cookie-law-accept-btn {
  background: var(--global-main-color) !important;
  border-color: var(--global-main-color) !important;
}

.react-cookie-law-accept-btn:hover {
  background: #000 !important;
}

.react-cookie-law-manage-btn:hover,
.react-cookie-law-save-btn:hover{
  background: #C9C9C9 !important;
}

/*# sourceMappingURL=react-big-calendar.css.map */

