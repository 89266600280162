

.form-wrapper {
    margin-top: 25px;
}

.login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 70px;
}


.login-H1 {
    word-wrap: break-word;
    font-size: 2.5em;
    opacity: 0.5;
    margin-top: 50px;
}

.login-H2 {
    word-wrap: break-word;
    font-size: 2.6em;
    position: relative;
    margin-bottom: 30px;
}

.login-H2:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 3px;
    background: var(--global-main-color);
}

@media ( max-width: 720px ) {  /** Größerer Viewport: Tablett **/
    .login-content {
        padding: 0px 10px;
    }
}
